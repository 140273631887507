// IE 10 and beyond : http://css-tricks.com/ie-10-specific-styles/
const doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);

// detect mobile device
const deviceAgent = navigator.userAgent.toLowerCase();
const mobile = deviceAgent.match(/(android|ipod|iphone|ipad)/);

('use strict');

// date & time
const dateObj = new Date();
const month = dateObj.getMonth() + 1; //months from 1-12
const day = dateObj.getDate();
const year = dateObj.getFullYear();

const hour = dateObj.getHours();
const minutes = dateObj.getMinutes();

// today
const icon = document.querySelector('.js-icon');
const time = document.querySelector('.js-time');

// Date
//EST
// offset = -5.0;

// utc = dateObj.getTime() + dateObj.getTimezoneOffset() * 60000;
// serverDate = new Date(utc + 3600000 * offset);
// document.querySelector('.js-date').innerHTML = serverDate.toLocaleString();
document.querySelector('.js-date').innerHTML = `${month}/${day}/${year}`;

// image based on time of day
const img = '/images/icons/';

// const img =
// 'https://gitlab.com/aoverstreet/static-html/-/raw/master/public/images/icons/';

// const g = 'good';
const m = 'morning';
const a = 'afternoon';
const e = 'evening';

if (hour < 12) {
    // morning
    time.innerHTML = `${m}!`;

    icon.src = `${img}${m}.svg`;
    icon.alt = `${m}!`;
    icon.classList.add(m);
} else if (hour < 17) {
    // afternoon
    time.innerHTML = `${a}!`;

    icon.src = `${img}${a}.svg`;
    icon.alt = `${a}!`;
    icon.classList.add(a);
} else {
    // evening
    time.innerHTML = `${e}!`;

    icon.src = `${img}${e}.svg`;
    icon.alt = `${e}!`;
    icon.classList.add(e);
}

// my profile - spam email
const email = document.querySelector('.js-email');

function mailto() {
    email.setAttribute(
        'href',
        'mailto:adam.overstreet@gmail.com?subject=welcome to adamoverstreet.com'
    );
}

email.addEventListener('click', mailto);

// bubble
if (!mobile) {
    const bubble = document.querySelectorAll('.js-bubble');
    const bubbleBg = document.querySelector('.my-profile__figcaption');
    const bubbleText = document.querySelector('.my-profile__svg-text');

    bubble.forEach(function (bubble) {
        const bubbleData = bubble.getAttribute('data-bubble-text');

        bubble.addEventListener('mouseover', showBubble);
        bubble.addEventListener('focusin', showBubble);

        bubble.addEventListener('mouseout', hideBubble);
        bubble.addEventListener('focusout', hideBubble);

        function showBubble() {
            bubbleBg.classList.add('u-is-visible');
            bubbleBg.classList.remove('u-is-hidden');

            bubbleText.innerHTML = `${bubbleData}`;
        }

        function hideBubble() {
            bubbleBg.classList.add('u-is-hidden');
            bubbleBg.classList.remove('u-is-visible');
        }
    });
}

// footer - displays current year
const currentYear = document.querySelectorAll('.js-year');

currentYear.forEach((currentYear) => {
    currentYear.innerHTML = `${year}`;
});

// Fetch all the details element.
const details = document.querySelectorAll('.details');

// Add the onclick listeners.
details.forEach((targetDetail) => {
    targetDetail.addEventListener('click', () => {
        // Close all the details that are not targetDetail.
        details.forEach((detail) => {
            if (detail !== targetDetail) {
                detail.removeAttribute('open');
            }
        });
    });
});

// console.log
let headerStyles = `
   width: 100%;
   border: 6px solid #0071bc;
   background-color: #fff;
   color: #0071bc;
   font-size: clamp(1.875rem, calc(1.8393rem + 0.1786vw), 2rem);
   text-align: center;
   padding: 10px;
   border-radius: 10px;
`;

let pStyles = `
   width: 100%;
   background-color: #fff;
   color: #000;
   font-size: clamp(1rem, calc(0.9643rem + 0.1786vw), 1.125rem);
   text-align: center;
   padding: 10px;
   border-radius: 10px;
`;

console.groupCollapsed(`%c👋 Welcome to adamoverstreet.com! 🔽 `, headerStyles);
console.log(`%c 🖥️ gitlab | https://gitlab.com/aoverstreet`, pStyles);
console.log(`%c 🖊️ codepen | https://codepen.io/adamoverstreet`, pStyles);
console.log(`%c 🔗 diigo | https://www.diigo.com/profile/aunderroad`, pStyles);
console.log(
    `%c 🤝 linkedin | https://www.linkedin.com/in/adamoverstreet`,
    pStyles
);
console.log(`%c 🐦 twitter | https://twitter.com/aunderroad`, pStyles);
console.log(`%c ⚽ meetup | https://www.meetup.com/members/5352013/`, pStyles);
console.log(`%c 📺 youtube |  https://www.youtube.com/user/underroad`, pStyles);
console.log(`%c 📥 email | adam.overstreet@gmail.com`, pStyles);
console.groupEnd();

// google analytics
window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', 'G-BSJ5BFBKRE');
